import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useStaticData from 'src/hooks/data/use-static-data'
import { AsyncMultipleSearchWithSelect } from '~/core/ui/AsyncMultipleSearchWithSelect'
import { Button } from '~/core/ui/Button'
import { CloseButton } from '~/core/ui/CloseButton'
import { DynamicImportForm } from '~/core/ui/DynamicImportForm'
import { IFormAction } from '~/core/ui/Form'
import { FormControlItem } from '~/core/ui/FormControlItem'
import { Input } from '~/core/ui/Input'
import { NativeSelect } from '~/core/ui/NativeSelect'
import { ISelectOption } from '~/core/ui/Select'
import { schemaAIWriterForm } from '~/lib/features/candidates/schema/validation-AI-writer'
import { IAIWriterForm } from '~/lib/features/candidates/types'
import useJobs from '~/lib/features/jobs/hooks/use-jobs'
import useBoundStore from '~/lib/store'

const AIWriterForm = ({
  onSubmit,
  isLoadingUpdateProfile = false,
  toggleAIWriter,
  defaultValue
}: {
  onSubmit?: (data: IAIWriterForm, formAction: IFormAction) => Promise<void>
  isLoadingUpdateProfile?: boolean
  toggleAIWriter?: () => void
  defaultValue: IAIWriterForm
}) => {
  const { t, i18n } = useTranslation()
  const { user } = useBoundStore()
  const { promiseSkillsOptions } = useJobs()
  const languageCollection = useStaticData({
    keyName: 'languages',
    locale: i18n.language
  })
  const languageList = languageCollection.map(
    (item: { language: string; code: string }) => {
      return {
        value: item.code,
        supportingObj: {
          name: item.language
        }
      }
    }
  )

  return (
    <DynamicImportForm
      isShowDebug={false}
      id="ai-writer-form"
      className="w-full"
      defaultValue={{
        ...defaultValue,
        language: user?.language
      }}
      schema={schemaAIWriterForm(t)}
      onSubmit={onSubmit}>
      {({ formState, control }) => {
        return (
          <>
            <div className="mb-4">
              <Controller
                control={control}
                name="headline"
                render={({ field: { onChange, value } }) => (
                  <FormControlItem
                    labelRequired
                    label={`${t(
                      'candidates:tabs:candidateOverview:profileInformation:position'
                    )}`}
                    destructive={
                      formState.errors && !!formState.errors?.headline
                    }
                    destructiveText={
                      formState.errors &&
                      (formState.errors?.headline?.message as string)
                    }>
                    <Input
                      autoFocus
                      placeholder={`${t(
                        'candidates:tabs:candidateOverview:profileInformation:add_position'
                      )}`}
                      size="sm"
                      onChange={onChange}
                      value={value}
                      destructive={
                        formState.errors && !!formState.errors?.headline
                      }
                    />
                  </FormControlItem>
                )}
              />
            </div>
            <div className="mb-4">
              <Controller
                control={control}
                name="skills"
                render={({ field: { onChange, value } }) => (
                  <FormControlItem
                    destructive={formState.errors && !!formState.errors.skills}
                    destructiveText={
                      formState.errors &&
                      (formState.errors.skills?.message as string)
                    }
                    labelRequired
                    label={`${t(
                      'candidates:tabs:candidateOverview:profileInformation:skills'
                    )}`}>
                    <AsyncMultipleSearchWithSelect
                      promiseOptions={promiseSkillsOptions}
                      size="sm"
                      onChange={onChange}
                      creatable
                      placeholder={
                        t(
                          'candidates:tabs:candidateOverview:profileInformation:addSkills'
                        ) as string
                      }
                      value={value}
                      configSelectOption={{
                        option: 'checkbox',
                        supportingText: ['name']
                      }}
                      classNameOverride={{
                        loadingMessage: `${t('label:loading')}`,
                        noOptionsMessage: `${t('label:noOptions')}`
                      }}
                      destructive={
                        formState.errors && !!formState.errors.skills
                      }
                    />
                  </FormControlItem>
                )}
              />
            </div>
            <div className="">
              <div>
                <Controller
                  control={control}
                  name="language"
                  render={({ field: { onChange, value } }) => {
                    const filter = languageList.filter(
                      (item: ISelectOption) => item.value === value
                    )

                    return (
                      <FormControlItem
                        destructive={
                          formState.errors && !!formState.errors.language
                        }
                        destructiveText={
                          formState.errors && formState.errors.language?.message
                        }
                        label={`${t('form:language_profile_field_label')}`}>
                        <NativeSelect
                          isClearable={false}
                          size="sm"
                          onChange={(newValue) => {
                            onChange((newValue as ISelectOption)?.value || '')
                          }}
                          value={filter}
                          options={languageList.filter((item: ISelectOption) =>
                            ['en', 'ja'].includes(item.value)
                          )}
                          destructive={
                            formState.errors && !!formState.errors.language
                          }
                          classNameOverride={{
                            loadingMessage: `${t('label:loading')}`,
                            noOptionsMessage: `${t('label:noOptions')}`
                          }}
                        />
                      </FormControlItem>
                    )
                  }}
                />
              </div>
            </div>

            <div className="mt-3 flex justify-end">
              <Button
                label={`${t('button:generate')}`}
                type="primary"
                size="sm"
                isDisabled={isLoadingUpdateProfile}
                isLoading={isLoadingUpdateProfile}
                htmlType="submit"
              />
            </div>
          </>
        )
      }}
    </DynamicImportForm>
  )
}
export default AIWriterForm
