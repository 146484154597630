import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseButton } from '~/core/ui/CloseButton'
import { IFormAction } from '~/core/ui/Form'
import IconWrapper from '~/core/ui/IconWrapper'
import { IAIWriterForm } from '~/lib/features/candidates/types'
import AIWriterForm from './AIWriterForm'
import AIWriterGenerateContent from './AIWriterGenerateContent'

const AIWriterWrapper: FC<{
  toggleAIWriter?: () => void
  onSubmit?: (data: IAIWriterForm, formAction: IFormAction) => Promise<void>
  loadingGenerate?: boolean
  textGenerate?: string
  defaultValue: IAIWriterForm
  onSubmitAddSummary?: () => void
  onRegenerate?: () => void
  isReplaceAIWriter?: boolean
}> = ({
  toggleAIWriter,
  onSubmit,
  loadingGenerate,
  textGenerate,
  defaultValue,
  onSubmitAddSummary,
  onRegenerate,
  isReplaceAIWriter
}) => {
  const { t } = useTranslation()

  return (
    <div className="px-4 py-3">
      <div className="mb-3">
        <div className="flex justify-between">
          <div className="text-lg font-semibold text-gray-900">
            {isReplaceAIWriter
              ? `${t('form:improve_AI')}`
              : `${t('form:ai_writer')}`}
          </div>
          <CloseButton
            iconClassName="w-4 h-4 flex items-start"
            onClick={toggleAIWriter}
          />
        </div>
        <div className="text-sm text-gray-500">
          {isReplaceAIWriter
            ? `${t('form:tooltip_replace_ai_writer')}`
            : `${t('form:tooltip_ai_writer')}`}
        </div>
      </div>

      {loadingGenerate ? (
        <div className="flex items-center">
          <IconWrapper
            className="animate-spin text-gray-500 dark:text-gray-400"
            name="Loader2"
            size={16}
          />
          <div className="ml-1 text-sm text-gray-500">
            {t('form:generating_loading')}
          </div>
        </div>
      ) : textGenerate ? (
        <AIWriterGenerateContent
          textGenerate={textGenerate}
          onRegenerate={onRegenerate}
          onSubmitAddSummary={onSubmitAddSummary}
          isReplaceAIWriter={isReplaceAIWriter}
        />
      ) : (
        <AIWriterForm
          toggleAIWriter={toggleAIWriter}
          onSubmit={onSubmit}
          defaultValue={defaultValue}
        />
      )}
    </div>
  )
}

export default AIWriterWrapper
