import { TFunction } from 'i18next'
import { z } from 'zod'

export const schemaAIWriterForm = (t: TFunction) => {
  return z.object({
    headline: z
      .string()
      .min(1, {
        message: `${t('form:requiredField')}`
      })
      .max(250, {
        message: `${t('form:field_max_number_required', { number: 250 })}`
      }),
    skills: z
      .array(
        z.object({
          value: z.string()
        })
      )
      .min(1, {
        message: `${t('form:requiredField')}`
      }),
    language: z.string().optional().nullish()
  })
}
